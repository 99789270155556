<template>
  <div class="medical-test">
    <i class="arrow"
       @click="$router.back(-1)"></i>
    <h2>填写记录</h2>
    <div class="content">
      <div class="item"
           v-for="(item,index) in questionList"
           :key="index">
        <h3>{{item.question_text}}</h3>
        <div class="select-wrap">
          <span :class="answer.id == item.answer_id ? 'select active' :'select'"
                v-for="(answer,index) in item.answers"
                :key="index">{{answer.answer_name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: PingFangSC;
  color: rgba(103, 103, 103, 1);
  -webkit-text-size-adjust: none;
  background: #fff;
}
.medical-test {
  width: 7.5rem;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  h2 {
    width: 100%;
    height: 1rem;
    background: rgba(0, 198, 174, 1);
    font-size: 0.4rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 1rem;
  }
  /deep/ .content {
    height: calc(100vh - 1.5rem);
    background: rgba(255, 255, 255, 1);
    border-radius: 0.26rem;
    padding: 0.5rem;
    overflow: auto;
    .item {
      margin-bottom: 0.46rem;
      h3 {
        font-size: 0.22rem;
        font-weight: 500;
        color: rgba(69, 69, 69, 1);
        text-align: left;
      }
      .select-wrap {
        display: flex;
        justify-content: space-around;

        .select {
          width: 0.9rem;
          height: 0.4rem;
          margin-top: 0.3rem;
          background: rgba(249, 249, 249, 1);
          border-radius: 0.25rem;
          font-size: 0.18rem;
          font-weight: 400;
          color: rgba(103, 103, 103, 1);
          line-height: 0.4rem;
          &.active {
            background: rgba(0, 198, 174, 0.1);
            color: #00c6ae;
          }
        }
      }
    }
  }
}
</style>

<script>
import { apiUrl, http } from "../config";
export default {
  name: 'medicalrecord',
  components: {
  },
  data () {
    return {
      questionList: []
    }
  },
  mounted () {
    let id = this.$route.query.id;
    let url = `/cmpa/answernaire/${id}`;
    http.get(url)
      .then((res) => {
        // let questionListArr = res.data;
        // questionListArr.map(item => {
        //   item.questionList.map(qitem => {
        //     this.questionList.push(qitem);
        //   });
        // });
        this.questionList = res.data
        console.log(this.questionList);
      });
  },
  methods: {
    back () {

    }
  }
}
</script>
